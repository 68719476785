import { ProAliasToken, ProConfigProvider } from "@ant-design/pro-provider";
import { ConfigProvider as AntdConfigProvider } from "antd";
import mnMN from "antd/lib/locale/mn_MN";
import { AppInitProvider } from "components";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Routes from "routes";
import { proMNLocale } from "./config";
import "./global.less";

const container: any = document.getElementById("root");
const root = createRoot(container);

const token = {
  colorPrimary: "rgb(38, 33, 97)",
  fontFamily: "'Rubik', 'PT Sans', sans-serif",
  fontSize: 13,
  boxShadow: "0 0 0 rgba(3, 9, 21, 0.5)",
} as Partial<ProAliasToken>;
root.render(
  <AntdConfigProvider
    locale={mnMN}
    theme={{
      token: token,
    }}
  >
    <ProConfigProvider valueTypeMap={proMNLocale} token={token}>
      <AppInitProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </AppInitProvider>
    </ProConfigProvider>
  </AntdConfigProvider>
);
