export const deleteConfirm = "delete me";
export const deleteConfirmReg = /^delete me$/g;
export const archiveConfirm = "archive me";
export const archiveConfirmReg = /^archive me$/g;
export const restoreConfirm = "restore me";
export const restoreConfirmReg = /^restore me$/g;
export const acceptConfirm = "accept me";
export const acceptConfirmReg = /^accept me$/g;

export const proMNLocale: any = {
  moneySymbol: "₮",
  form: {
    lightFilter: {
      more: "Цааш",
      clear: "Цэвэрлэх",
      confirm: "Хайх",
      itemUnit: " ",
    },
  },
  tableForm: {
    search: "Хайх",
    reset: "Цэвэрлэх",
    submit: "Илгээх",
    collapsed: "Нээх",
    expand: "Хаах",
    inputPlaceholder: "Оруулна уу",
    selectPlaceholder: "Сонгоно уу",
  },
  alert: {
    clear: "Цэвэрлэх",
    selected: "Сонгогдсон",
    item: "Бичилт",
  },
  pagination: {
    total: {
      range: " ",
      total: "ийн",
      item: "бичилтүүд",
    },
  },
  tableToolBar: {
    leftPin: "Зүүн талруу тэмдэглэх",
    rightPin: "Баруун талруу тэмдэглэх",
    noPin: "Тэмдэглээгүй",
    leftFixedTitle: "Зүүн талдаа хөдлөхгүй",
    rightFixedTitle: "Баруун талдаа хөдлөхгүй",
    noFixedTitle: "Хөдлөнө",
    reset: "Цэвэрлэх",
    columnDisplay: "Талбар харуулах",
    columnSetting: "Тохиргоо",
    fullScreen: "Дэлгэц дүүргэх",
    exitFullScreen: "Дэлгэц дүүргэлт гаргах",
    reload: "Дахин уншуулах",
    density: "Нягтаршил",
    densityDefault: "Үндсэн",
    densityLarger: "Том",
    densityMiddle: "Дунд",
    densitySmall: "Жижиг",
  },
  stepsForm: {
    next: "Дараах",
    prev: "Өмнөх",
    submit: "Дуусгах",
  },
  loginForm: {
    submitText: "Нэвтрэх",
  },
  editableTable: {
    action: {
      save: "Хадгалах",
      cancel: "Цуцлах",
      delete: "Устгах",
    },
  },
  switch: {
    open: "нээх",
    close: "хаах",
  },
};
