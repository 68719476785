/* eslint-disable react-refresh/only-export-components */
import { lazy } from "react";
import { IRoute } from "./types";

const NotFound = lazy(() => import("pages/exceptions/NotFound"));
const Home = lazy(() => import("pages/dashboard/home"));
const Admins = lazy(() => import("pages/dashboard/admins"));
const Customers = lazy(() => import("pages/dashboard/customers"));
const CustomerInfo = lazy(() => import("pages/dashboard/customers/view"));
const Organizations = lazy(() => import("pages/dashboard/organization"));
const ContactBooks = lazy(
  () => import("pages/dashboard/organization/contact_book")
);
const OrganizationCreate = lazy(
  () => import("pages/dashboard/organization/create")
);
const OrganizationUpdate = lazy(
  () => import("pages/dashboard/organization/update")
);

const Buildings = lazy(() => import("pages/dashboard/building"));
const BuildingCreate = lazy(() => import("pages/dashboard/building/create"));
const BuildingUpdate = lazy(() => import("pages/dashboard/building/update"));

const Equipments = lazy(() => import("pages/dashboard/equipment"));
const EquipmentCreate = lazy(() => import("pages/dashboard/equipment/create"));
const EquipmentUpdate = lazy(() => import("pages/dashboard/equipment/update"));

const Holders = lazy(() => import("pages/dashboard/holder"));
const HolderLogs = lazy(() => import("pages/dashboard/holder/log"));
const HolderInfo = lazy(() => import("pages/dashboard/holder/view"));
const HolderCreate = lazy(() => import("pages/dashboard/holder/create"));
const HolderUpdate = lazy(() => import("pages/dashboard/holder/update"));

const Cars = lazy(() => import("pages/dashboard/car"));
const CarLogs = lazy(() => import("pages/dashboard/car/log"));
const CarCreate = lazy(() => import("pages/dashboard/car/create"));
const CarUpdate = lazy(() => import("pages/dashboard/car/update"));

const Payments = lazy(() => import("pages/dashboard/payment"));
const QPayInvoices = lazy(() => import("pages/dashboard/qpay"));

const Banners = lazy(() => import("pages/dashboard/banner"));
const BannerLogs = lazy(() => import("pages/dashboard/banner/log"));
const BannerCreate = lazy(
  () => import("pages/dashboard/banner/tabs/banners/create")
);
const BannerUpdate = lazy(
  () => import("pages/dashboard/banner/tabs/banners/update")
);

const dashboardRoutes: IRoute[] = [
  {
    key: "home",
    path: "home",
    component: <Home />,
  },
  {
    key: "admin",
    path: "admin",
    component: <Admins />,
  },
  {
    key: "customer",
    path: "customer",
    component: <Customers />,
  },
  {
    key: "payment",
    path: "payment",
    component: <Payments />,
  },
  {
    key: "qpay",
    path: "qpay",
    component: <QPayInvoices />,
  },

  {
    key: "customer/view/:id",
    path: "customer/view/:id",
    component: <CustomerInfo />,
  },
  {
    key: "organization",
    path: "organization",
    component: <Organizations />,
  },
  {
    key: "organization/contact_book/:org_id",
    path: "organization/contact_book/:org_id",
    component: <ContactBooks />,
  },

  {
    key: "organization/create",
    path: "organization/create",
    component: <OrganizationCreate />,
  },
  {
    key: "organization/update/:id",
    path: "organization/update/:id",
    component: <OrganizationUpdate />,
  },
  {
    key: "building",
    path: "building",
    component: <Buildings />,
  },
  {
    key: "building/create",
    path: "building/create",
    component: <BuildingCreate />,
  },
  {
    key: "building/update/:id",
    path: "building/update/:id",
    component: <BuildingUpdate />,
  },
  {
    key: "equipment",
    path: "equipment",
    component: <Equipments />,
  },
  {
    key: "equipment/create",
    path: "equipment/create",
    component: <EquipmentCreate />,
  },
  {
    key: "equipment/update/:id",
    path: "equipment/update/:id",
    component: <EquipmentUpdate />,
  },
  {
    key: "holder",
    path: "holder",
    component: <Holders />,
  },
  {
    key: "holder/log",
    path: "holder/log",
    component: <HolderLogs />,
  },
  {
    key: "holder/create",
    path: "holder/create",
    component: <HolderCreate />,
  },
  {
    key: "holder/update/:id",
    path: "holder/update/:id",
    component: <HolderUpdate />,
  },
  {
    key: "holder/view/:id",
    path: "holder/view/:id",
    component: <HolderInfo />,
  },
  {
    key: "car",
    path: "car",
    component: <Cars />,
  },
  {
    key: "car/log",
    path: "car/log",
    component: <CarLogs />,
  },
  {
    key: "car/create",
    path: "car/create",
    component: <CarCreate />,
  },
  {
    key: "car/update/:id",
    path: "car/update/:id",
    component: <CarUpdate />,
  },
  {
    key: "banner",
    path: "banner",
    component: <Banners />,
  },
  {
    key: "banner/log",
    path: "banner/log",
    component: <BannerLogs />,
  },
  {
    key: "banner/create",
    path: "banner/create",
    component: <BannerCreate />,
  },
  {
    key: "banner/update/:id",
    path: "banner/update/:id",
    component: <BannerUpdate />,
  },
  {
    key: "not_found",
    path: "*",
    component: <NotFound />,
  },
];
export default dashboardRoutes;
