import { useRequest } from "ahooks";
import { Spin } from "antd";
import { authAtom } from "atoms/auth";
import { useAtom } from "jotai";
import { FC, ReactNode } from "react";
import auth from "services/auth";
import styles from "./styles.module.less";

interface Props {
  children?: ReactNode;
}

const AppInitProvider: FC<Props> = ({ children }) => {
  const [currentUser, setAuth] = useAtom(authAtom);

  const { loading } = useRequest(auth.info, {
    onSuccess: (user) => {
      setAuth(user);
    },
    onError: () => {
      auth.remToken();
      setAuth(undefined);
    },
  });

  if (!loading && currentUser) return <>{children}</>;
  if (!loading && !currentUser) return <>{children}</>;
  return (
    <div className={styles.container}>
      <Spin />
    </div>
  );
};

export default AppInitProvider;
