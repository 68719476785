import http from "../index";
import { FileRecord, MultiFileUpload, SingleFileUpload } from "./types";

namespace file {
  export const upload = async ({
    name,
    bucket_name,
    file,
    onUploadProgress,
  }: SingleFileUpload) => {
    const body = new FormData();

    body.append("name", name);
    body.append("file", file);
    body.append("bucket_name", bucket_name);

    return http.post<FileRecord>("/file/upload", {
      body,
      hasAuth: true,
      onUploadProgress: onUploadProgress,
    });
  };

  export const uploads = async ({
    files,
    names,
    bucket_name,
  }: MultiFileUpload) => {
    const body = new FormData();

    files.forEach((file, ind) => {
      body.append("names", names[ind]);
      body.append("files", file.originFileObj);
      body.append("bucket_name", bucket_name);
    });

    return http.post<FileRecord[]>("/file/multi/upload", {
      body,
      hasAuth: true,
    });
  };

  export const fileToUrl = (val?: string) => {
    if (!val) return undefined;
    return `${import.meta.env.VITE_FILE_GET_URL}${val}`;
  };

  export const getFileName = (val: string) => {
    return val.split("/").pop()?.substring(15);
  };
}

export default file;
