import { PageLoading } from "@ant-design/pro-layout";
import { ErrorBoundary } from "@ant-design/pro-utils";
import { authAtom } from "atoms/auth";
import { useAtom } from "jotai/react";
import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import dashboardRoutes from "routes/dashboard";
import auhtRoutes from "./auth";
import { IRoute } from "./types";
import auth from "services/auth";

const AuthLayout = lazy(() => import("layouts/auth"));
const DashboardLayout = lazy(() => import("layouts/dashboard"));
const NotAuthorized = lazy(() => import("pages/exceptions/NotAuthorized"));
const NotFound = lazy(() => import("pages/exceptions/NotFound"));

const MainRoutes: FC = () => {
  const location = useLocation();
  const [currentUser] = useAtom(authAtom);

  const routes: IRoute[] = [
    {
      path: "/auth",
      key: "auth",
      component: <AuthLayout />,
      children: auhtRoutes,
    },
    {
      path: "/403",
      key: "not_authorized",
      component: <NotAuthorized />,
    },
    {
      key: "not_found",
      path: "*",
      component: <NotFound />,
    },
  ];

  if (!!currentUser) {
    routes.push({
      path: "/dashboard",
      key: "dashboard",
      component: <DashboardLayout />,
      children: dashboardRoutes,
    });
  }

  return (
    <TransitionGroup>
      {/* <CSSTransition key={location.key} classNames="fade" timeout={300}> */}
      <Routes location={location}>
        <Route
          key="root"
          path="/"
          element={
            !!currentUser ? (
              <Navigate to={auth.reDirect(currentUser.admin_type)} />
            ) : (
              <Navigate to={"/auth/login"} />
            )
          }
        />
        {routes.map((route) =>
          !route.children ? (
            <Route
              key={route.key}
              path={route.path}
              element={
                <ErrorBoundary>
                  <Suspense fallback={<PageLoading />}>
                    {route.component}
                  </Suspense>
                </ErrorBoundary>
              }
            />
          ) : (
            <Route
              key={route.key}
              path={route.path}
              element={
                <ErrorBoundary>
                  <Suspense fallback={<PageLoading />}>
                    {route.component}
                  </Suspense>
                </ErrorBoundary>
              }
            >
              {route.children.map((item) => (
                <Route
                  key={item.key}
                  path={item.path}
                  element={
                    <ErrorBoundary>
                      <Suspense fallback={"loading"}>{item.component}</Suspense>
                    </ErrorBoundary>
                  }
                />
              ))}
            </Route>
          )
        )}
      </Routes>
      {/* </CSSTransition> */}
    </TransitionGroup>
  );
};

export default MainRoutes;
