/* eslint-disable react-refresh/only-export-components */
import { lazy } from "react";
import { IRoute } from "./types";

// const Login = lazy(() => import("pages/auth/login"));
const NotAuthorized = lazy(() => import("pages/exceptions/NotAuthorized"));
const NotFound = lazy(() => import("pages/exceptions/NotFound"));
const Login = lazy(() => import("pages/auth/login"));

const auhtRoutes: IRoute[] = [
  {
    key: "login",
    path: "login",
    component: <Login />,
  },
  {
    path: "403",
    key: "not_authorized",
    component: <NotAuthorized />,
  },
  {
    key: "not_found",
    path: "*",
    component: <NotFound />,
  },
];

export default auhtRoutes;
